





























































































import {
  AiSuggestedQuestion,
  AuditModel
} from '@/libs/Api';
import Page from '@/Page.vue';
import {Guid} from '@/libs/common/functions/guid';
import {Component, Prop, Ref, Watch} from 'vue-property-decorator';
import {auditsStore} from '@/libs/audits/+state/store';
import {CrudAction, CrudReponse} from '@/libs/core/+state/models/crud-action';
import {CrudGetter} from '@/libs/core/+state/models/crud-getter';
import {tenantUsersStore} from '@/libs/tenants/+state/store';
import apiService from '@/libs/core/api-service';

export enum GenerationActionEnum {
  AddToAudit = 'AddToAudit',
  Replace = 'Replace'
}

@Component({})
export default class AiQuestionGenerator extends Page {

  dialog = false;
  valid = true;
  saving = false;
  generating = false;
  canAdd = false;
  canReplace = false;
  generateDescription = true;
  generateDisagreementResponse = true;
  messageInput: string | null = null;
  suggestedQuestions: AiSuggestedQuestion[] = [];
  action: GenerationActionEnum = GenerationActionEnum.Replace;
  selected: any[] = [];

  get headers() {
    const headers = [
      { text: "Kategorie", value: "category", align: "left", sortable: false },
      { text: "Kód otázky", value: "code", align: "left", sortable: false },
      { text: "Otázka", value: "question", align: "left", sortable: false }
    ];
    if (this.generateDescription) {
      headers.push({ text: "Podrobosti k otázce", value: "description", align: "left", sortable: false });
    }
    if (this.generateDisagreementResponse) {
      headers.push({ text: "Reakce na neshodu", value: "disagreementResponse", align: "left", sortable: false });
    }
    return headers;
  }

  protected GenerationActionEnum = GenerationActionEnum;

  @Watch("dialog")
  dialogChanged(v: boolean): void {
    if (!v)
      this.close();
  }

  @Watch("selected")
  selectedChanged(v: any[]): void {
    console.log(v);
  }

  @Ref() form!: any;
  @Prop() tenantId!: string;
  @Prop() audit!: AuditModel;

  open(onlyAdd = false): void {
    this.dialog = true;
    if (onlyAdd) {
      this.canAdd = true;
      this.canReplace = false;
      this.action = GenerationActionEnum.AddToAudit;
    }
    else {
      this.canAdd = true;
      this.canReplace = true;
      this.action = GenerationActionEnum.Replace;
    }
  }

  close(): void {
    this.dialog = false;
  }

  generate(): void {
    this.generating = true;
    this.suggestedQuestions = [];
    if (this.messageInput != null && this.messageInput.length > 0) {
      apiService.api.generateQuestions(this.audit!.id!, {
        messages: [
          {
            message: this.messageInput
          }
        ],
        generateDescription: this.generateDescription,
        generateDisagreementResponse: this.generateDisagreementResponse
      }).then((e) => {
        this.suggestedQuestions = e.data.returnValue?.suggestedQuestions ?? [];
        this.generating = false;
        this.selected = this.suggestedQuestions;
      }).catch((e) => {
        console.error(e);
        this.generating = false;
        this.selected = [];
      });
    }
  }

  addToAudit() {
    this.$emit(
        "onResult",
        GenerationActionEnum.AddToAudit,
        this.selected
    );
  }

  replace() {
    this.$emit(
        "onResult",
        GenerationActionEnum.Replace,
        this.selected
    );
  }

}
